// Override Bootstrap's Sass default variables
//
// Nearly all variables in Bootstrap are written with the `!default` flag.
// This allows you to override the default values of those variables before
// you import Bootstrap's source Sass files.
//
// Overriding the default variable values is the best way to customize your
// CSS without writing _new_ styles. For example, you can either change
// `$body-color` or write more CSS that override's Bootstrap's CSS like so:
// `body { color: red; }`.

// Include parts of Bootstrap

// Include functions first
@import "bootstrap/scss/functions";

// Toggle any global options (https://getbootstrap.com/docs/5.3/customize/options/)
$enable-negative-margins: true;

// Change any defaults
$blue: #0568FD; // darken slightly to increase contrast on elements using $light: $gray-100 (ie bg-light, #f8f9fa)
//$cyan: #117C96; // EGRP utilizes this shade, BS 4-info color

$btn-close-opacity: .75; // darken all defaults for btn-close
$btn-close-hover-opacity: 1;
$btn-close-disabled-opacity: .5;


// Required
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

// Include any custom maps or map overrides here

// NCI Color Palette added to Bootstrap base theme
// Removed nci-accent-warm, redundant w/ nci-warning
/*$nci-colors: (
  'nci-base': #71767a,
  'nci-base-light': #a9aeb1,
  'nci-base-lighter': #dfe1e2,
  'nci-base-lightest': #f0f0f0,
  'nci-base-dark': #565c65,
  'nci-base-darker': #3d4551,
  'nci-base-darkest': #1b1b1b,
  'nci-primary': #007bbd,
  'nci-primary-light': #3395ca,
  'nci-primary-lighter': #99cae4,
  'nci-primary-dark': #004971,
  'nci-primary-darker': #00314b,
  'nci-primary-vivid': #067cbc,
  'nci-secondary': #3c989e,
  'nci-secondary-light': #4bbfc6,
  'nci-secondary-lighter': #beebee,
  'nci-secondary-dark': #226467,
  'nci-secondary-darker': #1e4c4f,
  'nci-secondary-vivid': #338084,
  'nci-accent-cool': #5478ab,
  'nci-accent-cool-light': #92a9c8,
  'nci-accent-cool-lighter': #d7e5f4,
  'nci-accent-cool-dark': #284976,
  'nci-accent-cool-darker': #06162d,
  'nci-disabled': #c9c9c9,
  'nci-disabled-light': #e6e6e6,
  'nci-disabled-dark': #adadad,
  'nci-error': #bb0e3d,
  'nci-error-light': #f1c5d2,
  'nci-error-lighter': #f1c5d2,
  'nci-error-dark': #950b30,
  'nci-error-darker': #700824,
  'nci-info': #077bbd,
  'nci-info-light': #66afd7,
  'nci-info-lighter': #d4e7f2,
  'nci-info-dark': #006297,
  'nci-info-darker': #004971,
  'nci-success': #3c989e,
  'nci-success-light': #93d8dc,
  'nci-success-lighter': #dbf2f3,
  'nci-success-dark': #298085,
  'nci-success-darker': #1e4c4f,
  'nci-warning': #face00,
  'nci-warning-light': #fee685,
  'nci-warning-lighter': #fdf2bf,
  'nci-warning-dark': #ddaa01,
  'nci-warning-darker': #b38c00
);
// Merge the maps
$theme-colors: map-merge($theme-colors, $nci-colors);*/

// Additional Spacers
$more-space: (
    6: $spacer * 5,
);
$spacers: map-merge($spacers, $more-space);

// Required
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

// Comment out parts not needed 
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
//@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";